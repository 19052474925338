#projects {
  padding: 5% 10%;
}

.header {
  font-family: 'Shadows Into Light', cursive !important;
  letter-spacing: 5px;
  text-align: center;
  transition: all .5s;
  font-size: 25px !important;
}

.header:hover {
  transform: scale(1.3);
  color: red !important;
}

.meta {
  text-align: center;
}

.projects-container {
  margin-top: 30px !important;
}

.project-img {
  border-top: solid 1px black;
}

.project-description {
  padding: 1em;
  border-top: 1px solid rgba(34, 36, 38, .2);
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 15px;
}