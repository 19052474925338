#skills {
  padding: 5% 10%;
  background-color: #1b1c1d;
  color: white;
}

.category {
  display: flex;
  flex-direction: row;
  margin-bottom: 1%;
}

.category-mobile {
  display: flex;
  flex-direction: column;
  margin-bottom: 1%;
}

.skills-header-mobile {
  margin-bottom: 5px
}

.skills-header, .category-name {
  text-align: right;
  flex: 1;
}

.category-name,
.category-name-mobile {
  padding-top: 0.4em;
  letter-spacing: 1px;
  font-family: 'Indie Flower', cursive;
  font-size: 20px;
}

.skills-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.legend {
  margin-top: 5px;
}

.skills-container,
.legend {
  flex: 2;
  margin-left: 20px;
}

@keyframes wiggle {
  0% { transform: translate(2px, 2px) rotate(0deg) }
  10% { transform: translate(-2px, -4px) rotate(-10deg) }
  20% { transform: translate(-6px, 0px) rotate(10deg) }
  30% { transform: translate(6px, 4px) rotate(0deg) }
  40% { transform: translate(2px, -2px) rotate(10deg) }
  50% { transform: translate(-2px, 4px) rotate(-10deg) }
  60% { transform: translate(-6px, 2px) rotate(0deg) }
  70% { transform: translate(6px, 2px) rotate(-10deg) }
  80% { transform: translate(-2px, -2px) rotate(10deg) }
  90% { transform: translate(2px, 4px) rotate(0deg) }
  100% { transform: translate(2px, -4px) rotate(-10deg) }
}

.skill-item:hover,
.skill-item:focus,
.skill-item:active {
  animation: wiggle 3s infinite;
}