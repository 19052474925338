#intro {
  background-image: url(../assets/home.jpg);
  background-size: cover;
  min-height: 100%;
  max-height: 800px;
  display: flex;
  align-items: center;
  background-position: center top;
}

.heading {
  background: rgba(0, 0, 0, 0.7);
  height: 40%;
  width: 100%;
  color: white;
  text-align: center;
  padding: 5% 0 5% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titles {
  font-family: "Indie Flower", cursive;
  letter-spacing: 4px;
  font-size: 30px;
  margin-bottom: 5%;
  margin-top: 5%;
  animation: moveInBottom 2s ease-out;
}

@media screen and (min-width: 1401px) {
  .titles {
    font-size: 40px;
  }
}

@media screen and (max-width: 1100px) and (min-width: 951px) {
  .titles {
    font-size: 25px;
  }
}

@media screen and (max-width: 950px) and (min-width: 801px),
  (max-width: 380px) {
  .titles {
    font-size: 20px;
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(5rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}
