#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1b1c1d;
  color: white;
  padding: 5% 10%;
}

.links {
  display: flex;
  flex-direction: row;
  padding: 3% 0;
  width: 100%;
  justify-content: space-evenly
}

.contact-logo {
  transition: transform .5s;
}

.contact-logo:hover {
  transform: scale(1.5);
}

.copyright {
  font-family: 'Indie Flower', cursive;
  letter-spacing: 2px;
}