/* latin */
@font-face {
  font-family: 'Indie Flower';
  src: url(../assets/Indie_Flower/IndieFlower-Regular.ttf) format('truetype');
}

/* latin-ext */
@font-face {
  font-family: 'Kaushan Script';
  src: url(../assets/Kaushan_Script/KaushanScript-Regular.ttf) format('truetype');
}

/* latin */
@font-face {
  font-family: 'Shadows Into Light';
  src: url(../assets/Shadows_Into_Light/ShadowsIntoLight-Regular.ttf) format('truetype');
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

::-webkit-scrollbar {
  display: none;
}

.contents {
  height: calc(100% - 80px);
  overflow: scroll;
}

.section_header {
  font-family: 'Kaushan Script', cursive;
  letter-spacing: 5px;
  font-size: 35px;
  margin-bottom: 30px;
}

#about {
  display: flex;
  padding: 5% 10%;
  flex-direction: column;
}

.non-click {
  cursor: default !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
}

@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }

  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}

.brian,
.section_header {
  line-height: 1.5;
}

.brian:hover,
.section_header:hover {
  background-image: -webkit-linear-gradient(92deg, #f35626, #feab3a);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: hue 2s infinite linear;
  -webkit-animation: hue 2s infinite linear;
  cursor: pointer;
}